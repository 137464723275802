button {
  box-shadow: none !important;
  font-family: 'Rubik', sans-serif !important;
}

a {
  text-transform: none !important;
  box-shadow: none !important;
  font-family: 'Rubik', sans-serif !important;
  text-decoration: none;
}
*,
html {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif !important;
}

body {
  background-color: #fff;
  font-family: 'Rubik', sans-serif !important;
}
main {
  min-height: 100vh;
  background-color: #fff !important;
}

.body {
  font-family: 'Rubik';
  padding-bottom: 15px;
}

.click {
  cursor: pointer;
}
.select-square .css-13cymwt-control {
  border-radius: 0 !important;
}
.react-select * {
  font-size: 14px !important;
}
.react-select .css-13cymwt-control {
  height: 52px;
  font-size: 14px;
}
.react-select .css-t3ipsp-control {
  height: 52px;
  outline: 1.5px solid #1f53d7 !important;
}
.react-select .css-1nmdiq5-menu {
}
.react-select-sm .css-13cymwt-control {
  height: 37px;
  font-size: 14px;
}
.react-select-sm .css-t3ipsp-control {
  height: 37px;
  font-size: 13px;
  outline: 1.5px solid #1f53d7 !important;
}
.react-select-sm .css-1nmdiq5-menu {
}
.popover {
  margin-top: -10px !important;
}
.popover * {
  box-shadow: rgba(159, 159, 159, 0.16) 0px 1px 4px !important;
}

.popover_list {
  list-style: none;
  padding: 0;
  box-shadow: none !important;
}
.popover_list li {
  border: none;
  padding: 6px 20px;
  font-size: 12px;
  color: #222;
  cursor: pointer;
  box-shadow: none !important;
}
.popover_list li:last-child {
  color: red;
}
.popover_list li:hover {
  background-color: #f1f1f1;
}
.ellispisText {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dashboardBox {
  background: #f9f9f9;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 3px;
  padding: 20px;
  border: 0.5px solid #dfdfdf;
}

/* .dashboardBox:hover {
  border: 0.5px solid #dfdfdf;
} */

.hover {
  cursor: pointer;
  padding: 5px 15px;
  display: block;
}

.hover:hover {
  padding: 5px 15px;
  background: #efefef;
  /* border-radius: 3px; */
}

.hover2 {
  cursor: pointer;
  padding: 5px 15px;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}

.hover2:hover {
  padding: 7px 15px;
  background: #efefef;
  border-radius: 3px;
}

.selected {
  background: #eef5fc;
  border-radius: 10px;
  padding: 7px 15px;
}

.selected2 {
  background: #ffffff;
  /* tab shade */

  border: 0.3px solid #878181;
  border-radius: 5px;
  padding: 7px 15px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #1f53d7;
}

.status {
  background: #fbf4dc;
  border: 1px solid #f2994a;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 8px 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #f2994a;
}

.comingSoon {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 8.57143px;
  line-height: 14px;
  color: #f2994a;
  display: inline-block;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5.71429px 11.4286px;

  /* white */

  background: #ffffff;
  /* Orange */

  border: 0.714286px solid #f2994a;
  box-sizing: border-box;
  border-radius: 14.2857px;
}
.no-padding-x {
  padding-left: 0px !important;
}
.no-padding-horizontal {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.push-left-right {
  display: flex !important;
  justify-content: space-between !important;
}

.outline-box {
  background: #ffffff;

  /* Gray 3 */
  border: 1px solid rgba(22, 40, 40, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
}
.center {
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}

.center2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonend {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.space-right {
  margin-right: 20px !important;
}

.space-right2 {
  margin-right: 10px !important;
}

.space-left {
  margin-left: 20px !important;
}

.space-left2 {
  margin-left: 10px !important;
}
.space-top {
  margin-top: 20px !important;
}

.space-top2 {
  margin-top: 10px;
}

.space-bottom {
  margin-bottom: 20px;
}

.space-bottom2 {
  margin-bottom: 10px;
}

.space-y {
  margin-top: 15px;
  margin-bottom: 15px;
}
.padding-x {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.padding-xx {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.padding-y {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.padding {
  padding: 10px !important;
}

.margin {
  margin: 10px !important;
}
.margin-x {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.padding1 {
  padding: 15px !important;
}
.padding2 {
  padding: 20px !important;
}

.white {
  color: #fff !important;
}

.dark {
  color: #333333 !important;
}

.light {
  color: #878181 !important;
}

.blue {
  color: #1f53d7 !important;
}
.red {
  color: red !important;
}

.green {
  color: #00a3a1 !important;
}
.green2 {
  color: green !important;
}

.italic {
  font-style: italic !important;
}

.bold {
  font-weight: 500 !important;
}
.nocase {
  text-transform: none !important;
}
.shift-right {
  text-align: right !important;
  display: block !important;
}
.shift-center {
  display: block !important;
  text-align: center !important;
}

.mLeft {
  width: 100px;
  text-align: right !important;
  display: block !important;
  justify-content: flex-end !important;
  padding-right: 10px;
}

.mLeft2 {
  width: 150px;
  text-align: right !important;
  display: block !important;
  justify-content: flex-end !important;
  padding-right: 10px;
}
.mRight {
  width: 320px;
  display: block !important;
}

.ruby {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.input {
  background: #fcffff;
  /* border hue */

  border-radius: 3px;
  font-size: 30px !important;
}

.inputFile {
  overflow: hidden;
  position: relative;
  display: block;
  box-sizing: border-box;
  width: max-content;
  border-radius: 4px;
  border: 1px solid white;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 3px;
  min-width: 50%;
  margin: 0px;
}
.l {
  justify-content: left;
}
.inputFileInner {
  cursor: pointer;
  display: block;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.searchContainer {
  border-radius: 3px;
  padding: 8px 10px 3px 10px;

  background: #fcffff;
  /* border hue */

  border: 1px solid #dfdfdf;
}
.searchContainerInner,
.searchContainerInner:active,
.searchContainerInner:hover {
  border: none;
  background: #fcffff;
  position: absolute;
  top: 0px;
  left: 10%;
  width: 90%;

  padding: 10px 10px;
}

.react-datepicker {
  font-family: Rubik;
  border: 0px;

  background: #ffffff;
  /* tab shade */

  border: 0.392578px solid #878181;
  /* pop up shadow */

  box-shadow: 0px 8px 24px 0.694947px rgba(51, 63, 81, 0.15);
  border-radius: 7.85156px;
}

.react-datepicker-popper,
.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 0px;
}

.react-datepicker__triangle {
  display: none;
}
.react-datepicker__month-container {
  color: #000;
}
.react-datepicker__header,
.react-datepicker__day--selected {
  background-color: #3f51b5;
  color: white;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-names,
.react-datepicker__day-name {
  font-family: Rubik;
  color: white;
}

.datepicker {
  width: 100% !important;

  padding: 10px;
  background: #fcffff;
  /* border hue */

  border: 1px solid #dfdfdf;
  border-radius: 3px;
}

.hr {
  /* background: #C4C4C4; */
  border: none;
  height: 0.1px;
}

.hr2 {
  background: #fff !important;
  border: none;
  height: 2px;
}

.hr3 {
  background: #000000 !important;
  border: none;
  height: 2px;
}

.hr4 {
  background: #dfdfdf !important;
  border: none;
  height: 2px;
}

.hr5 {
  background: #c4c4c4 !important;
  border: none;
  height: 0.1px;
}
.hr6 {
  background: #336666 !important;
  height: 40px;
  border-radius: 50px;
}

hr {
  background: #dfdfdf !important;
  border-width: 0.1px !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.table {
  display: table !important;
}

.list {
  display: list-item !important;
}

.underline {
  text-decoration: underline !important;
}

.same {
  display: inherit !important;
}
.shift-left {
  display: block !important;
  text-align: left !important;
}
.vertical-text {
  transform: rotate(270deg);
  transform-origin: left bottom 0;
  float: left;
  position: absolute;
  bottom: 50%;
}

.box {
  background: #eef5fc;
  /* border hue */

  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px;
}

.box2 {
  background: #fcffff;
  /* border hue */

  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px;
}

.box3 {
  background: #26293f;
  border-radius: 4px;
  padding: 20px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  /* white */

  color: #ffffff;
}

.boxLeft1 {
  background: #f2f2f2;
  border: 0.4px solid rgba(40, 40, 40, 0.72);
  border-radius: 3px;
  border-right: 5px solid #27ae60;
}
.boxRight1 {
  background: #f2f2f2;
  border: 0.4px solid rgba(40, 40, 40, 0.72);
  border-radius: 3px;
  border-left: 5px solid #27ae60;
}

.boxLeft2 {
  background: #f2f2f2;
  border: 0.4px solid rgba(40, 40, 40, 0.72);
  border-radius: 3px;
  border-right: 5px solid #0f83ef;
}
.boxRight2 {
  background: #f2f2f2;
  border: 0.4px solid rgba(40, 40, 40, 0.72);
  border-radius: 3px;
  border-left: 5px solid #56ccf2;
}

.boxLeft3 {
  background: #f2f2f2;
  border: 0.4px solid rgba(40, 40, 40, 0.72);
  border-radius: 3px;
  border-right: 5px solid #2d9cdb;
}
.boxRight3 {
  background: #f2f2f2;
  border: 0.4px solid rgba(40, 40, 40, 0.72);
  border-radius: 3px;
  border-left: 5px solid #2d9cdb;
}

.error {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: rgba(219, 0, 0, 0.8);
  font-style: italic;
}

.text {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
}

.sign {
  background: #f2f2f2;
  padding: 10px;
  margin-left: -14px;
  font-size: 12px;
}

.align-right {
  text-align: right;
  display: block;
}

.xs {
  font-size: 10.6395px !important;
}
.sm {
  font-size: 12.6395px !important;
}

.md {
  font-size: 16px !important;
}

.lg {
  font-size: 18px !important;
}

.lg2 {
  font-size: 24px !important;
}

.modalDeleteHeader {
  background: #eb5757;
  /* tab shade */

  /* border: 0.392578px solid #878181;*/
  border-radius: 7.85156px 7.85156px 0px 0px;
  padding: 20px;
}

.modalHeader {
  padding: 20px 20px 10px 20px;
}

.modal {
  background: #ffffff;
  /* tab shade */

  /* border: 0.392578px solid #878181;*/
  /* pop up shadow */

  /* box-shadow: 0px 8px 24px 0.694947px rgba(51, 63, 81, 0.15); */
  border-radius: 7.85156px;
  min-width: 400px;
  padding-bottom: 15px;
}

.modalBody {
  min-height: 50px;
  padding: 20px 40px;
}

.cancel {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
  background: #ffffff;
  /* tab shade */

  border: 0.458843px solid #878181;
  border-radius: 9.17686px;
  cursor: pointer;
  padding: 7px;
  display: table;
}

.cancel:hover {
  border: 1px solid #878181;
  background: #eef5fc;
}

.truncate {
  white-space: nowrap;
  width: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.truncate:hover {
  overflow: visible;
  white-space: unset;
}

.nowrap {
  white-space: nowrap;
}

.placeholderBox {
  border: 1px dashed #000000;
  border-radius: 5px;
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  padding: 50px 30px;
  min-width: 150px;
  background-color: #c4c4c4;
}
.invoiceWrapper {
  width: 8.3in;
  min-width: 8.3in;
  max-width: 8.3in;
  margin: 0 auto;
}

.invoiceBox {
  border: 0.5px solid #dfdfdf;
  border-radius: 5px 5px 0px 0px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 11.2542px 14.6305px 2.25084px rgba(60, 83, 105, 0.25);
}

.invoiceBox2 {
  border: 0.5px solid #dfdfdf;
  border-radius: 5px 5px 0px 0px;

  background: #ffffff;
  box-shadow: 0px 11.2542px 14.6305px 2.25084px rgba(60, 83, 105, 0.25);
}
.text2 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 133.9%;
  /* identical to box height, or 21px */

  color: #111118;
}

.push-right {
  text-align: right;
  justify-content: flex-end !important;
}

.select {
  padding: 10px 10px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
}

.select * {
  padding: 10px 10px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
}
.TextFieldInput {
  background-color: #fff;
}
.selectMenu {
  background-color: #fff !important;
}

.selectMenu *:focus {
  background-color: #fff !important;
}

.MenuItem {
  font-family: 'Rubik', sans-serif !important;
  font-size: 14px !important;
}

.text3 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;

  color: #111118;
}

.vertical {
  border: solid 2px #c4c4c4;
  width: 2px;
  height: 205px;
  padding: 0px;
  margin: 0px;
  display: inline-block;
}

.circle {
  margin: 0px;
  margin-top: -10px;
  border: solid 5px #c4c4c4;
  width: 40px;
  border-radius: 100px;
  padding: 5px;
}

.TextFieldLeft1 fieldset {
  border-radius: 4px 0px 0px 0px;
  border-width: 1px 0px 0px 1px;
}

.TextFieldLeft2 fieldset {
  border-radius: 0px 0px 0px 4px;
}

.TextFieldRight1 fieldset {
  border-radius: 0px 4px 0px 0px;
  border-width: 1px 1px 0px 0px;
}
.TextFieldRight2 fieldset {
  border-radius: 0px 0px 4px 0px;
  border-width: 1px 1px 1px 0px;
}

.TextFieldMiddle fieldset {
  border-radius: 0;
  border-width: 1px 1px 0px 1px;
}

.circleLogo {
  padding: 15px 20px;
  background-color: #111118;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.pendingValidation {
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4.90756px 9.81513px rgba(0, 0, 0, 0.12);
  border-radius: 3.06723px;
  border-left: solid 5px #ff7e46;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
}

.validated {
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4.90756px 9.81513px rgba(0, 0, 0, 0.12);
  border-radius: 3.06723px;
  border-left: solid 5px #00dc7d;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
}

.TINInfo {
  padding: 20px;
  background: #26293f;
  box-shadow: 0px 4.90756px 9.81513px rgba(0, 0, 0, 0.12);
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
}

.reverse {
  transform: rotate(180deg);
}

.rbc-btn-group {
  display: flex !important;
  justify-content: flex-end;
  text-align: right;
  align-self: flex-end;
  align-content: flex-end;
  align-items: flex-end;
}

.rbc-toolbar-label {
  text-align: left !important;
  margin-top: -25px;
  font-weight: 500;
  display: block;
}

.sample:after {
  display: block;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: rgb(105, 105, 222);
  width: max-content;
  content: '—';
}

.rbc-toolbar {
  display: block !important;
}

.rbc-event {
  background-color: red !important;
}

.rbc-event-content:hover {
  overflow: visible !important;
  white-space: unset !important;
}

/* Pagination */
.paginationWrapper {
  padding: 16px 0;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
}
